<template>
<div style="height: 27rem">
    <LoadingDataError v-if="loadingError.isError" :ErrorCode="loadingError.errorCode" />

    <ScrollPanel v-if="!loadingError.isError" style="width: 100%; height: 370px" class="custom-scrollbar">
    <Divider align="left" style="margin-top: 0">
        <div class="p-d-inline-flex p-ai-center" style="background-color: none">
            <span style="font-weight: 700">Arrival Information</span>
        </div>
    </Divider>
    <div class="arrival-info p-grid">
        <div class="p-md-4 p-sm-4">
            <label for="arrival_date">Arrival Date: </label>
            <span class="p-ml-4">{{ arrivalInfo.arrival_date }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="arrival_by">Arrival By:</label>
            <span class="p-ml-4">{{ arrivalInfo.arrival_by }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="flight_vehicle_no" v-if="arrivalInfo.arrival_by == 'By Flight'">Flight No.:</label>
            <label for="flight_vehicle_no" v-if="arrivalInfo.arrival_by == 'By Bus'">Vehicle No.:</label>
            <label for="flight_vehicle_no" v-if="arrivalInfo.arrival_by == 'By Boat'">Boat name:</label>
            <label for="flight_vehicle_no" v-if="arrivalInfo.arrival_by == 'By Cruise'">Cruise No.:</label>
            <span class="p-ml-4">{{ arrivalInfo.flight_vehicle_no }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="arrival_time">Arrival Time:</label>
            <span class="p-ml-4">{{ arrivalInfo.arrival_time }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="arrival_from_to">From-To:</label>
            <span class="p-ml-4">{{ arrivalInfo.arrival_from_to }}</span>
        </div>
    </div>
    <Divider align="left">
        <div class="p-d-inline-flex p-ai-center" style="background-color: none">
            <span style="font-weight: 700">Departure Information</span>
        </div>
    </Divider>
    <div class="depart-info p-grid">
        <div class="p-md-4 p-sm-4">
            <label for="depart_date">Depart Date: </label>
            <span class="p-ml-4">{{ departInfo.depart_date }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="depart_by">Depart By:</label>
            <span class="p-ml-4">{{ departInfo.depart_by }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="flight_vehicle_no" v-if="departInfo.depart_by == 'By Flight'">Flight No.:</label>
            <label for="flight_vehicle_no" v-if="departInfo.depart_by == 'By Bus'">Vehicle No.:</label>
            <label for="flight_vehicle_no" v-if="departInfo.depart_by == 'By Boat'">Boat name:</label>
            <label for="flight_vehicle_no" v-if="departInfo.depart_by == 'By Cruise'">Cruise No.:</label>
            <span class="p-ml-4">{{ departInfo.flight_vehicle_no }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="depart_time">Depart Time:</label>
            <span class="p-ml-4">{{ departInfo.depart_time }}</span>
        </div>
        <div class="p-md-4 p-sm-4">
            <label for="depart_from_to">From-To:</label>
            <span class="p-ml-4">{{ departInfo.depart_from_to }}</span>
        </div>
    </div>
    <Divider align="left">
        <div class="p-d-inline-flex p-ai-center" style="background-color: none">
            <span style="font-weight: 700">Landing Area</span>
        </div>
    </Divider>
    <div class="depart-info p-grid">
        <div class="p-col-4">
            <label >Area:</label>
        </div>
        <div class="p-col-4">
            <label >From Date:</label>
        </div>
        <div class="p-col-4">
            <label>To Date:</label>
        </div>
    </div>
    <div class="p-grid" v-for="(item) of landingAreaInfo" :key="item.id">
        <div class="p-col-4">
            <span>{{ item.area }}</span>
        </div>
        <div class="p-col-4">
            <span>{{ item.from }}</span>
        </div>
        <div class="p-col-4">
            <span>{{ item.to }}</span>
        </div>
    </div>
    </ScrollPanel>
</div>
</template>

<script>
import { ref, reactive, } from 'vue'
import dayjs from 'dayjs'

import LoadingDataError from '../components/LoadingDataError.vue'
import BookingService from '../service/BookingService'

export default {
    setup() {
        let arrivalInfo = reactive({})
        let departInfo = reactive({})
        let landingAreaInfo = ref([])
        let loadingError = reactive({isError: false, errorCode: ''})

        const bookingService = ref(new BookingService())

        function getBookingArrivalInfo(BookingID) {
            if(BookingID != 0) {
                clearData()

                bookingService.value.getArrivalInfo(BookingID).then(data => {
                    if(!data.errorResponse) {
                        Object.assign(arrivalInfo, data.arrivalData[0])
                        arrivalInfo.arrival_date = dayjs(arrivalInfo.arrival_date).format('DD-MMM-YYYY')
                        
                        Object.assign(departInfo, data.departData[0])
                        departInfo.depart_date = dayjs(departInfo.depart_date).format('DD-MMM-YYYY')
                
                        landingAreaInfo.value = data.landingAreaData.map(item => {
                            return {
                                id: item.id,
                                area: item.area,
                                from: dayjs(item.from).format('DD-MMM-YYYY'),
                                to: dayjs(item.to).format('DD-MMM-YYYY'),
                            }
                        })
                        loadingError.isError = false
                    } else {
                        // toast.add({severity:'warn', summary: 'Error ' + data.status, detail: 'Fetching booking arrival information failed.', life: 5000});
                        loadingError.isError = true
                        loadingError.errorCode = data.status
                    }
                })
            }
        }

        const clearData = () => {
            for (var a_key in arrivalInfo) {
                delete arrivalInfo[a_key];
            }
            for (var d_key in departInfo) {
                delete departInfo[d_key];
            }
            landingAreaInfo.value.length = 0
        }

        return {
            arrivalInfo,
            departInfo,
            landingAreaInfo,
            getBookingArrivalInfo,
            loadingError,
        }
    },
    components: {
        LoadingDataError,
    }
}
</script>

<style lang="scss" scoped>
.p-divider.p-divider-horizontal .p-divider-content {
    background-color: #fff;
}
.arrival-info {

    label {
        width: 85px;
        // font-weight: 700;
        float: left;
    }

    span {
        width: 87px;
        float: left;
    }
}
.depart-info {

    label {
        width: 87px;
        // font-weight: 700;
        float: left;
    }

    span {
        width: 87px;
        float: left;
    }
}
</style>